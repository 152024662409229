import { BookingPolicyDto } from '../../types/shared-types';

export interface BodyViewModel {
  message?: string;
  isBookOnlineAllowed: boolean;
}

export const bodyViewModelFactory = ({
  bookingPolicyDto,
  t,
}: {
  bookingPolicyDto: BookingPolicyDto;
  t: (key: string) => string;
}): BodyViewModel => {
  const message = bookingPolicyDto.isBookOnlineAllowed
    ? getAvailabilityMessage(bookingPolicyDto, t)
    : '';
  return {
    message,
    isBookOnlineAllowed: bookingPolicyDto.isBookOnlineAllowed && !message,
  };
};

const getAvailabilityMessage = (
  bookingPolicyDto: BookingPolicyDto,
  t,
): string => {
  if (bookingPolicyDto.isFullBooked) {
    return t('app.widget.uou-messages.fully-booked');
  } else if (bookingPolicyDto.isTooEarlyToBook) {
    const timeUntilServiceIsOpen = bookingPolicyDto.timeUntilServiceIsOpen;
    return t('app.widget.uou-messages.book-is-not-open-yet', {
      timeUntilServiceIsOpen,
    });
  } else if (bookingPolicyDto.isTooLateToBook) {
    return t('app.widget.uou-messages.book-already-closed');
  } else if (!bookingPolicyDto.isServiceAvailable) {
    return t('app.widget.uou-messages.book-not-available');
  } else {
    return '';
  }
};
