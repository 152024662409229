import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
// import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import settingsParams from './settingsParams';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSchedule } from '../../api/schedule.api';
import { ServiceType } from '@wix/bookings-uou-types/dist/src';
import { getSettingsValues } from '@wix/tpa-settings';
import { biDefaults } from './bi/consts';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);
  const { setProps } = flowAPI.controllerConfig;

  const isMobile = flowAPI.isMobile();
  const isRTL = flowAPI.isRTL();

  return {
    async pageReady() {
      const publicData =
        flowAPI.controllerConfig.config.publicData.COMPONENT || {};
      const settings = getSettingsValues(publicData, settingsParams);
      const instance = flowAPI.controllerConfig.appParams.instance as string;
      // const experiments = await flowAPI.getExperiments();
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const isPreview = wixCodeApi.window.viewMode === 'Preview';
      const t = flowAPI.translations.t;
      const loggerDefaults = flowAPI.biLogger?.util.updateDefaults(biDefaults);

      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};
      if (!flowAPI.inEditor || isPreview) {
        const httpClient = new HttpClient({ isSSR: flowAPI.isSSR() });
        const servicePageIndex = wixCodeApi.location.path.indexOf(
          'booking-service-page',
        );
        const isServiceSlugExistsInPath =
          servicePageIndex !== -1 &&
          servicePageIndex !== wixCodeApi.location.path.length - 1;
        const serviceSlug = isServiceSlugExistsInPath
          ? wixCodeApi.location.path[servicePageIndex + 1]
          : '';
        const config = await httpClient.request(getConfig, {
          headers: { authorization: instance },
        })(serviceSlug, isPreview);

        navigateToCalendar = async () => {
          wixCodeApi.location.to &&
            wixCodeApi.location.to(config.serviceDto.fullUrl + '/book');
        };

        // await wixCodeApi['wix-seo'].renderSEOTags({
        //   itemType: ITEM_TYPES.BOOKINGS_SERVICE,
        //   itemData: config.SEO.serviceResponse,
        //   seoData: userOverrides //JSON string from BO SEO panel
        // });

        if (!flowAPI.isSSR()) {
          const fixFormat = (dateFormat) => {
            if (dateFormat) {
              return new Date(dateFormat).toISOString();
            } else {
              return '';
            }
          };

          const serviceType = config.serviceDto.type;
          const isCourse = serviceType === ServiceType.COURSE;
          const isIndividual = serviceType === ServiceType.INDIVIDUAL;

          if (!isIndividual) {
            const now = new Date();
            const from = isCourse
              ? fixFormat(
                  config?.SEO?.serviceResponse?.schedules[0].firstSessionStart,
                )
              : now.toISOString();
            const to = isCourse
              ? fixFormat(
                  config?.SEO?.serviceResponse?.schedules[0].lastSessionEnd,
                )
              : new Date(now.setDate(now.getDate() + 7)).toISOString();
            if (from && to) {
              httpClient
                .request(getSchedule, {
                  headers: { authorization: instance },
                })(
                  [config?.SEO?.serviceResponse?.service?.scheduleIds[0]],
                  from,
                  to,
                )
                .then((schedule) =>
                  setProps({
                    schedule,
                  }),
                )
                .catch((e) => {
                  console.log('Getting schedules failed ', e);
                });
            } else {
              setProps({
                schedule: [],
              });
            }
          }
        }
        viewModel = servicePageViewModelFactory({
          config,
          t,
        });
      } else {
        viewModel = dummyViewModelFactory({ t });
      }
      setProps({
        navigateToCalendar,
        schedule: undefined,
        viewModel,
        isMobile,
        isRTL,
      });
    },
  };
};

export default createController;
