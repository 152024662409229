import { CatalogServiceDto } from '@wix/bookings-uou-types/dist/src';
import { DaysOfferedMapper } from '../../mappers/days-offered.mapper';
import {
  DurationMapper,
  durationMapperOptions,
} from '../../mappers/duration.mapper';
import { PaymentDtoMapper } from '../../mappers/payment-dto.mapper';
import { LocationMapper } from '../../mappers/location.mapper';

export interface DetailsSectionViewModel {
  daysOffered?: string;
  duration?: string;
  price?: string;
  location?: string;
  isBookOnlineAllowed: boolean;
}

export const detailsSectionViewModelFactory = ({
  t,
  regionalSettingsLocale,
  serviceDto,
  isBookOnlineAllowed,
}: {
  t: Function;
  regionalSettingsLocale: string;
  serviceDto: CatalogServiceDto;
  isBookOnlineAllowed: boolean;
}): DetailsSectionViewModel => {
  const serviceType = serviceDto?.type;
  const isDurationVaries = serviceDto?.scheduleHeader?.isDurationVaries;
  const startDateAsUTC = serviceDto?.scheduleHeader?.startDateAsUTC;
  const durationInMinutes = serviceDto?.scheduleHeader?.durationInMinutes;

  const daysOffered = new DaysOfferedMapper(
    t,
    regionalSettingsLocale,
    { allWeek: 'days-offered.all-week' },
    false,
  ).text({
    serviceType,
    daysDetails: serviceDto?.scheduleHeader?.daysDetails ?? [],
  });
  const durationOptions: durationMapperOptions = {
    hourUnit: 'duration.units.hours',
    minuteUnit: 'duration.units.minutes',
    durationVaries: 'duration.varies',
    coursePassedText: 'service.course.schedule.start-date-passed',
    courseInFutureText: 'service.course.schedule.start-date-in-future',
  };
  const duration = new DurationMapper(
    regionalSettingsLocale,
    durationOptions,
    t,
  ).durationText({
    serviceType,
    isDurationVaries,
    startDateAsUTC,
    durationInMinutes,
  });

  const price = new PaymentDtoMapper(regionalSettingsLocale).priceText(
    serviceDto?.payment,
  );
  const location = new LocationMapper().text({
    serviceLocation: serviceDto?.info?.location,
    clientLocationText: t('service.location.customer-place'),
  });

  return {
    daysOffered,
    duration,
    price,
    location,
    isBookOnlineAllowed,
  };
};
